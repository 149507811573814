import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from "element-ui"
import VueAwesomeSwiper from 'vue-awesome-swiper'

import '../node_modules/swiper/dist/css/swiper.css'

import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI, VueAwesomeSwiper)

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


