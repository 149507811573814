import Vue from 'vue'
import VueRouter from 'vue-router'

// const Home = () => import('@/views/home/index');
Vue.use(VueRouter);

let url = "/"
if (
  process.env.NODE_ENV == "production" &&
  process.env.VUE_APP_FLAG == "prod"
) {
  url = "hekHome"
} else if (process.env.NODE_ENV === "development") {
  url = "hekHomeDev"
} 

const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/home/index"),
    },
    {
      path: "/solution",
      name: "solution",
      component: () => import("@/views/home/solution"),
    },
    {
      path: "/aboutUs",
      name: "aboutUs",
      component: () => import("@/views/home/aboutUs"),
    },
    {
      path: "/contactUs",
      name: "contactUs",
      component: () => import("@/views/home/contactUs"),
    },
];

const router = new VueRouter({
    mode: 'history',//表示访问路径不带#号
    base: url,
    routes
});

router.beforeEach((to, from, next) => {
    next()
    // if (to.path !== '/login' && !Vue.prototype.globalData.currentAgent) {
    //     next({path: '/login'})
    // } else next()
});

export default router
